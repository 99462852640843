.price-section {
	background-image: url("/src/images/svg/bg-shape-yellow-price-390.svg"),
		url("/src/images/svg/bg-shape-blue-price-390.svg"),
		url("/src/images/svg/bg-shape-yellow-price-390-secondary.svg");
	background-repeat: no-repeat, no-repeat, no-repeat;
	background-position: calc(50% + 56px) calc(50% - 576px),
		calc(50% - 24px) calc(50% + 800px), calc(50% + 56px) calc(50% + 744px);
	background-size: auto, auto, auto;
	padding-top: 80px;
	margin-top: -80px;
	padding-bottom: 320px;
	margin-bottom: -320px;
	@include mq(tablet-S) {
		background-image: url("/src/images/svg/bg-shape-blue-price-768.svg"),
			url("/src/images/svg/bg-shape-yellow-price-768-secondary.svg");
		background-repeat: no-repeat, no-repeat;
		background-position: calc(50% + 80px) calc(50% + 320px),
			calc(50% + 232px) calc(50% + 248px);
		background-size: auto, auto;
		padding-top: 0;
		margin-top: 0;
		padding-bottom: 160px;
		margin-bottom: -160px;
	}
	@include mq(tablet-L) {
		background-position: calc(50% + 304px) calc(50% + 320px),
			calc(50% + 432px) calc(50% + 200px);
	}
	@include mq(desktop) {
		background-position: calc(50% + 328px) calc(50% + 224px),
			calc(50% + 448px) calc(50% + 160px);
		background-size: 2220px, 2220px;
	}
}

.price-container {
	@include flex(flex, center, stretch);
	flex-direction: column;
	gap: $L;
	padding-top: $L;
	@include mq(tablet-S) {
		padding-top: $XL;
		padding-bottom: $XL;
	}
	@include mq(tablet-L) {
		flex-direction: row;
		gap: $M;
	}
}

.price-cards-list {
	@include flex(flex, center, stretch);
	flex-direction: column;
	gap: $M;
	@include mq(tablet-S) {
		flex-direction: row;
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 1.48);
	}
}

.price-card {
	@include flex(flex, flex-start, stretch);
	flex-direction: column;
	min-height: 596px;
	border-radius: $S;
	@include mq(tablet-S) {
		flex-basis: calc((100% - 1 * $M) / 2);
		min-height: 0;
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
	}
}

.price-card-with-label {
	position: relative;
}

.best-choice-label {
	position: absolute;
	top: 0;
	right: 12px;
}

// Стилі для 1, 3 картки, тощо

.price-card:nth-child(odd) {
	background-color: $primaryColor950;
	box-shadow: $shadowElevationMedium;
}

.price-card:nth-child(odd) .price-card-header {
	box-shadow: $M $M 60px 0 rgba(28, 137, 212, 0.1);
}

.price-card:nth-child(odd) .price-card-title {
	color: $neutralColor0;
}

.price-card:nth-child(odd) .price-card-body {
	box-shadow: $M $M 60px 0 rgba(28, 137, 212, 0.1);
}

.price-card:nth-child(odd) p {
	color: $primaryColor100;
}

.price-card:nth-child(odd) .price-card-button {
	color: $neutralColor0;
}

// Стилі для 2, 4 картки, тощо

.price-card:nth-child(even) {
	// background-color: $neutralColor0;
	background-color: $neutralColor0Transparency600;
	// border: 1px solid $primaryColor950;
	box-shadow: $shadowElevationMedium;
}

.price-card:nth-child(even) .price-card-title {
	color: $primaryColor950;
}

.price-card:nth-child(even) .price-card-body {
	border-top: 1px solid $neutralColor300;
	border-bottom: 1px solid $neutralColor300;
}

.price-card:nth-child(even) p {
	color: $neutralColor950;
}

// Загальні стилі для всіх карток

.price-card-superheader {
	margin-bottom: 0;
}

.price-card-header {
	padding: $M;
}

.price-card-title {
	font-family: $secondaryFontFamily;
	@include font(800, 3.25rem, 3.5rem, 0);
	text-transform: uppercase;
}

.price-card-title > sup {
	@include font(800, 1rem, 1.5rem, 0);
	text-transform: uppercase;
	vertical-align: super;
}

.price-card-body {
	padding: $M;
	flex-grow: 1;
}

.price-card-benefits-list {
	@include flex(flex, center, flex-start);
	flex-direction: column;
	gap: $M;
}

.price-card-benefits-item {
	@include flex(flex, center, flex-start);
	gap: $XS;
}

.price-card-benefits-icon {
	stroke: $accentColor500;
}

.price-card-footer {
	padding: $M 32px;
}

// Інші стилі секції

.price-description-container {
	@include flex(flex, center, center);
	flex-direction: column;
	@include mq(tablet-S) {
		flex-direction: row;
		gap: $M;
	}
	@include mq(tablet-L) {
		flex-direction: column;
		flex-basis: calc((100% - 2 * $M) / 3);
		padding-top: $M;
		padding-bottom: $M;
		padding-left: $M;
	}
	@include mq(desktop) {
		align-items: flex-start;
	}
}

.price-description-wrapper {
	@include flex(flex, center, stretch);
	flex-direction: column;
}

.price-description-text {
	color: $neutralColor950;
	margin-bottom: $L;
	padding-right: $M;
}

.price-description-button {
	margin-bottom: $L;
	@include mq(tablet-S) {
		margin-bottom: 0;
	}
}
