.hero-section {
	background-image: url("/src/images/iphone-hero.webp"),
		url("/src/images/svg/bg-ellipse-yellow-hero-390.svg"),
		url("/src/images/svg/bg-ellipse-blue-hero-390.svg");
	background-repeat: no-repeat, no-repeat, no-repeat;
	background-position: calc(50% + 120px) calc(50% + 48px),
		calc(50% + 16px) calc(50% + 204px), calc(50% + 160px) calc(50% + 36px);
	background-size: 352px, auto auto, 640px;
	padding-top: 88px;
	padding-bottom: 224px;
	margin-bottom: -224px;
	@include mq(tablet-S) {
		background-image: url("/src/images/iphone-hero-side-768.webp"),
			url("/src/images/iphone-hero-front-768.webp"),
			url("/src/images/svg/bg-shape-yellow-hero-768.svg"),
			url("/src/images/svg/bg-ellipse-blue-hero-768.svg");
		background-position: calc(50% + 304px) calc(50% - 32px),
			calc(50% + 128px) calc(50% - 88px), calc(50% + 56px) calc(50% + 88px),
			calc(50% + 336px) calc(50% - 32px);
		background-size: 340px, 328px, 634px, 528px;
	}
	@include mq(tablet-L) {
		background-position: calc(50% + 392px) calc(50% - 96px),
			calc(50% + 180px) calc(50% - 144px), calc(50% + 96px) calc(50% + 80px),
			calc(50% + 416px) calc(50% - 80px);
		background-size: 444px, 432px, 928px, 776px;
		padding-bottom: 320px;
		margin-bottom: -320px;
	}
	@include mq(desktop) {
		background-image: url("/src/images/iphone-hero-side-768.webp"),
			url("/src/images/iphone-hero-front-768.webp"),
			url("/src/images/svg/bg-shape-yellow-hero-768.svg"),
			url("/src/images/svg/bg-ellipse-blue-hero-desktop.svg");
		background-position: calc(50% + 424px) calc(50% - 88px),
			calc(50% + 180px) calc(50% - 144px), calc(50% + 80px) calc(50% + 32px),
			calc(50% + 256px) calc(50% + 128px);
		background-size: 525px, 512px, 928px, 1920px;
		padding-bottom: 320px;
		margin-bottom: -320px;
	}
}

.hero-container {
	@include flex(flex, flex-start, center);
	@include mq(tablet-S) {
		padding-top: $L;
		padding-bottom: $L;
	}
	@include mq(tablet-L) {
		padding-top: $XL;
		padding-bottom: $XL;
	}
	@include mq(desktop) {
		padding-top: 136px;
		padding-bottom: 128px;
	}
}

.call-to-action-wrapper {
	@include flex(flex, center, flex-start);
	flex-direction: column;
	gap: $M;
	padding-bottom: 116px;
	@include mq(tablet-S) {
		flex-basis: calc((100% - 1 * $M) / 2);
		padding-bottom: 0;
	}
}

.hero-title {
	font-family: $secondaryFontFamily;
	color: $primaryColor950;
	text-wrap: balance;
	@include font(800, 3.75rem, 4.25rem, -0.1rem);
	@include mq(tablet-L) {
		@include font(800, 5rem, 5.5rem, -0.1rem);
	}
}

.hero-text {
	max-width: 15rem;
	text-wrap: balance;
	margin-bottom: 20px;
	@include mq(tablet-S) {
		max-width: 100%;
	}
}

.buttons-app-download {
	@include flex(flex, center, center);
	flex-direction: column;
	gap: $S;
	@include mq(tablet-S) {
		flex-direction: row;
		gap: $M;
	}
}

.bond-ua-app-google-link,
.bond-ua-app-apple-link {
	border-radius: $S;
	background-color: $neutralColor0Transparency600;
	transform: scale(1);
	box-shadow: $shadowElevationMedium;
	transition: background-color $mainTransitionDuration
			$mainTransitionTimingFunction,
		box-shadow $mainTransitionDuration $mainTransitionTimingFunction,
		transform $mainTransitionDuration $mainTransitionTimingFunction;
	// border: 1px solid $primaryColor900;
	// background-color: $neutralColor0;
	transition: background-color $mainTransitionDuration
		$mainTransitionTimingFunction;
	&:hover,
	&:focus {
		background-color: $primaryColor950;
		transform: scale(0.994);
		box-shadow: $shadowElevationLow;
	}
}

.bond-ua-app-google-link-vector-neutral950 {
	fill: $primaryColor950;
	stroke: $neutralColor950;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction,
		stroke $mainTransitionDuration $mainTransitionTimingFunction;
	.bond-ua-app-google-link:hover &,
	.bond-ua-app-google-link:focus & {
		fill: $neutralColor0;
		stroke: $neutralColor0;
	}
}

.bond-ua-app-google-link-vector-primary950,
.bond-ua-app-apple-link-primary950 {
	fill: $primaryColor950;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

.bond-ua-app-google-link:hover .bond-ua-app-google-link-vector-primary950,
.bond-ua-app-apple-link:hover .bond-ua-app-apple-link-primary950 {
	fill: $neutralColor0;
}
