.products-section {
	background-image: url("/src/images/svg/bg-shape-products-390.svg");
	background-repeat: no-repeat;
	background-position: calc(50% + 304px) calc(50% - 24px);
	@include mq(tablet-S) {
		background: none;
	}
}

.products-container {
	@include flex(flex, center, stretch);
	flex-direction: column;
	gap: $L;
	padding-top: $XL;
	padding-bottom: $XL;
	@include mq(tablet-S) {
		gap: $XL;
		padding-bottom: $L;
	}
	@include mq(tablet-L) {
		flex-direction: row;
		gap: $M;
		padding-top: $L;
	}
	@include mq(desktop) {
		align-items: flex-end;
		padding-top: $XL;
		padding-bottom: $XL;
	}
}

.products-buttons-list {
	@include flex(flex, center, stretch);
	flex-direction: column;
	gap: $M;
	@include mq(tablet-S) {
		flex-direction: row;
		flex-wrap: wrap;
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
	}
}

.products-buttons-item {
	@include mq(tablet-S) {
		flex-basis: calc((100% - 3 * $M) / 4);
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
		height: calc((100% - 1 * $M) / 2);
	}
}

.products-button {
	display: block;
	width: 100%;
	font-family: $secondaryFontFamily;
	color: $neutralColor600;
	text-transform: uppercase;
	@include font(800, 1rem, 1.5rem, 0);
	border-radius: $S;
	// border: 1px solid $primaryColor900;
	border: none;
	padding: $M 0;
	// background-color: $neutralColor0;
	background-color: $neutralColor0Transparency600;
	box-shadow: $shadowElevationMedium;
	transition: color $mainTransitionDuration $mainTransitionTimingFunction,
		background-color $mainTransitionDuration $mainTransitionTimingFunction,
		box-shadow $mainTransitionDuration $mainTransitionTimingFunction,
		transform $mainTransitionDuration $mainTransitionTimingFunction;
	&:hover,
	&:focus {
		cursor: pointer;
		color: $primaryColor100;
		background-color: $primaryColor950;
		transform: scale(0.994);
		box-shadow: $shadowElevationLow;
	}
	@include mq(tablet-S) {
		padding: $L 0;
	}
	@include mq(tablet-L) {
		height: 100%;
	}
}

.shares-button {
	color: $primaryColor100;
	background-color: $primaryColor950;
}

.shares-button-icon,
.bonds-button-icon,
.depositary-button-icon,
.repositary-button-icon {
	margin: 0 auto $XS;
}

.bonds-button-icon-layer1,
.depositary-button-icon-layer5 {
	fill: $neutralColor500;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

// .shares-button-icon-layer1,
.bonds-button-icon-layer2,
.depositary-button-icon-layer1,
.repositary-button-icon-layer-accent {
	fill: $primaryColor400;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

// .shares-button-icon-layer3,
.bonds-button-icon-vector1,
.depositary-button-icon-vector9,
.repositary-button-icon-layer-neutral {
	fill: $neutralColor600;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

.repositary-button-icon-vector5 {
	fill: $neutralColor500;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

.shares-button .shares-button-icon-layer1 {
	fill: $primaryColor700;
}

.shares-button .shares-button-icon-layer3 {
	fill: $primaryColor100;
}

// .shares-button:hover .shares-button-icon-layer1,
// .shares-button:focus .shares-button-icon-layer1,
.bonds-button:hover .bonds-button-icon-layer2,
.bonds-button:focus .bonds-button-icon-layer2,
.depositary-button:hover .depositary-button-icon-layer1,
.depositary-button:focus .depositary-button-icon-layer1,
.repositary-button:hover .repositary-button-icon-layer-accent,
.repositary-button:focus.repositary-button-icon-layer-accent {
	fill: $primaryColor700;
}

// .shares-button:hover .shares-button-icon-layer3,
// .shares-button:focus .shares-button-icon-layer3,
.bonds-button:hover .bonds-button-icon-vector1,
.bonds-button:focus .bonds-button-icon-vector1,
.bonds-button:hover .bonds-button-icon-layer1,
.bonds-button:focus .bonds-button-icon-layer1,
.depositary-button:hover .depositary-button-icon-vector9,
.depositary-button:focus .depositary-button-icon-vector9,
.depositary-button:hover .depositary-button-icon-layer5,
.depositary-button:focus .depositary-button-icon-layer5,
.repositary-button:hover .repositary-button-icon-layer-neutral,
.repositary-button:focus .repositary-button-icon-layer-neutral,
.repositary-button:hover .repositary-button-icon-vector5,
.repositary-button:focus .repositary-button-icon-vector5 {
	fill: $primaryColor100;
}

.products-description-container {
	@include mq(tablet-S) {
		max-width: 662px;
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
		padding-left: $M;
	}
}

.products-description-subheader {
	margin-bottom: $S;
}

.products-description-text {
	margin-bottom: 40px;
	max-width: 460px;
}
